exports.components = {
  "component---src-page-templates-chat-chat-video-id-tsx": () => import("./../../../src/page-templates/chat/{Chat.videoId}.tsx" /* webpackChunkName: "component---src-page-templates-chat-chat-video-id-tsx" */),
  "component---src-page-templates-generated-batch-comments-comments-content-id-comments-batch-id-tsx": () => import("./../../../src/page-templates/__generated_batch/comments/{Comments.content_id}/{Comments._batch_id}.tsx" /* webpackChunkName: "component---src-page-templates-generated-batch-comments-comments-content-id-comments-batch-id-tsx" */),
  "component---src-page-templates-video-video-video-id-tsx": () => import("./../../../src/page-templates/video/{Video.videoId}.tsx" /* webpackChunkName: "component---src-page-templates-video-video-video-id-tsx" */),
  "component---src-page-templates-video-video-video-parts-slug-tsx": () => import("./../../../src/page-templates/video/{Video.videoParts__slug}.tsx" /* webpackChunkName: "component---src-page-templates-video-video-video-parts-slug-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-channel-[name]-tsx": () => import("./../../../src/pages/channel/[name].tsx" /* webpackChunkName: "component---src-pages-channel-[name]-tsx" */),
  "component---src-pages-channel-channel-user-id-tsx": () => import("./../../../src/pages/channel/{Channel.userId}.tsx" /* webpackChunkName: "component---src-pages-channel-channel-user-id-tsx" */),
  "component---src-pages-channel-channel-user-name-tsx": () => import("./../../../src/pages/channel/{Channel.userName}.tsx" /* webpackChunkName: "component---src-pages-channel-channel-user-name-tsx" */),
  "component---src-pages-chat-[name]-tsx": () => import("./../../../src/pages/chat/[name].tsx" /* webpackChunkName: "component---src-pages-chat-[name]-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-video-[name]-tsx": () => import("./../../../src/pages/video/[name].tsx" /* webpackChunkName: "component---src-pages-video-[name]-tsx" */)
}

